import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';

export default function TrainingCompanyAdd({training_company, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [formTrainingCompany,setformTrainingCompany] = useState(training_company);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);


    function addNewTrainingCompany(){
        dispatch({type:'create_new_tab',name:'Nová školiaca firma',page:'TrainingCompanyAdd',props:{training_company: null,type:'add'},mask:'skol002'})
      }
      function copyTrainingCompany(){
        dispatch({type:'create_new_tab',name:'Nová školiaca firma',page:'TrainingCompanyAdd',props:{training_company: formik.values,type:'copy'},mask:'skol002'})
      }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formTrainingCompany==null?{
            name: '',address: '',tel_number:'',fax:'',email:'',web_address:'',contact_person:'',description:'',}
            :formTrainingCompany,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/training_companies', formData,true)
                .then((response) => {
                    console.log(response);
                    getRequest("/api/training_companies").then((response) => {
                        dispatch({type:'load_training_companies',newTrainingCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_companies/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/training_companies").then((response) => {
                        dispatch({type:'load_training_companies',newTrainingCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(training_company_id) {
        return state.training_companies.findIndex(obj => obj.id === training_company_id);
    }

    async function scrollToFirst(){
        let my_training_company = state.training_companies[0];
        setformTrainingCompany(my_training_company);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setformTrainingCompany(state.training_companies[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.training_companies.length){
            setformTrainingCompany(state.training_companies[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_companies.length-1;
        let my_training_company = state.training_companies[last];
        setformTrainingCompany(my_training_company);
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingCompany()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTrainingCompany()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová škol. firma","TrainingCompanyAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingCompanyAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   KARTA ŠKOLIACEJ FIRMY
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV firmy
                        </label>
                        <input
                            name='name'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TELEFÓNNE ČÍSLO
                        </label>
                        <input
                            name='tel_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.tel_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-MAIL
                        </label>
                        <input
                            name='email'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.email}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="contact_person"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            KONTAKTNÁ OSOBA
                        </label>
                        <input
                            name='contact_person'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.contact_person}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ADRESA
                        </label>
                        <input
                            name='address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fax"
                            className="block text-md font-semibold text-gray-800"
                        >
                             FAX
                        </label>
                        <input
                            name='fax'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.fax}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="web_address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            WEB. ADRESA
                        </label>
                        <input
                            name='web_address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.web_address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>  
                        <button className='hidden' type='submit' ref={submitRef}></button>    
            </div>
            </div>
            </div> 
             </form>
            </div>
            </> 
            );
} 
