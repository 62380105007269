import React,{useContext} from 'react';

import {
    Tab,Box
} from '@mui/material';

import {
    TabList,
    TabContext
 } from '@mui/lab';
 import CloseIcon from '@mui/icons-material/Close';
 import HomeIcon from '@mui/icons-material/Home';
 import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { DatabaseContext } from '../redux/DatabaseStore';
import HomePage from '../pages/HomePage';
import InternaChyba from '../pages/otk/InternaChyba';
import ExternaChyba from '../pages/otk/ExternaChyba';
import Reklamacia from '../pages/otk/Reklamacia';
import ZoznamInt from '../pages/otk/ZoznamInt';
import ZoznamExt from '../pages/otk/ZoznamExt';
import ZoznamRek from '../pages/otk/ZoznamRek';
import ProfilePage from '../pages/ProfilePage';
import DocumentationAdd from '../pages/otk/DocumentationAdd';
import Documentation from '../pages/otk/Documentation';
import DocumentationDetail from '../pages/otk/DocumentationDetail';
import MeasurementProtocol from '../pages/otk/MeasurementProtocol';
import SelfControl from '../pages/otk/SelfControl';
import NDTProtocol from '../pages/otk/NDTProtocol';
import AnnealingProtocol from '../pages/otk/AnnealingProtocol';
import CustomerList from '../pages/options/CustomerList';
import CustomerAdd from '../pages/options/CustomerAdd';
import SupplierAdd from '../pages/options/SupplierAdd';
import SupplierList from '../pages/options/SupplierList';
import EmployeeList from '../pages/options/EmployeeList';
import UserList from '../pages/options/UserList';
import UserAdd from '../pages/options/UserAdd';
import RoleList from '../pages/options/RoleList';
import RoleAdd from '../pages/options/RoleAdd';
import TabPanel from './TabPanel';
import EightDreport from '../pages/print/EightDreport';
import EmployeeAdd from '../pages/options/EmployeeAdd';
import PaintingIntervalAdd from '../pages/painting/options/PaintingIntervalAdd';
import PaintingIntervalList from '../pages/painting/options/PaintingIntervalList';
import PaintingConditionAdd from '../pages/painting/PaintingConditionAdd';
import PaintingConditionList from '../pages/painting/PaintingConditionList';
import PaintingTimeConditionAdd from '../pages/painting/options/PaintingTimeConditionAdd';
import PaintingTimeConditionList from '../pages/painting/options/PaintingTimeConditionList';
import PaintingEmployeeConditionAdd from '../pages/painting/options/PaintingEmployeeConditionAdd';
import PaintingEmployeeConditionList from '../pages/painting/options/PaintingEmployeeConditionList';
import DepartmentList from '../pages/options/DepartmentList';
import DepartmentAdd from '../pages/options/DepartmentAdd';
import GaugeList from '../pages/calibration/GaugeList';
import GaugeAdd from '../pages/calibration/GaugeAdd';
import GaugeCalibrationList from '../pages/calibration/GaugeCalibrationList';
import GaugeCalibrationAdd from '../pages/calibration/GaugeCalibrationAdd';
import CalibrationCompanyList from '../pages/calibration/CalibrationCompanyList';
import CalibrationCompanyAdd from '../pages/calibration/CalibrationCompanyAdd';
import CalibrationCompanyCertificateList from '../pages/calibration/CalibrationCompanyCertificateList';
import CalibrationCompanyCertificateAdd from '../pages/calibration/CalibrationCompanyCertificateAdd';
import PriorityList from '../pages/priorities/PriorityList';
import PriorityListMobile from '../pages/priorities/PriorityListMobile';
import PriorityAdd from '../pages/priorities/PriorityAdd';
import PriorityDepartmentAdd from '../pages/priorities/options/PriorityDepartmentAdd';
import PriorityDepartmentList from '../pages/priorities/options/PriorityDepartmentList';
import PriorityLoadAutomaticList from '../pages/priorities/options/PriorityLoadAutomaticList';
import PriorityLoadManuallyList from '../pages/priorities/options/PriorityLoadManuallyList';
import PriorityLoadAdd from '../pages/priorities/options/PriorityLoadAdd';
import ErrorTypeList from '../pages/otk/options/ErrorTypeList';
import ErrorTypeAdd from '../pages/otk/options/ErrorTypeAdd';
import PreventionList from '../pages/otk/options/PreventionList';
import PreventionAdd from '../pages/otk/options/PreventionAdd';
import GroupList from '../pages/otk/options/GroupList';
import GroupAdd from '../pages/otk/options/GroupAdd';
import MeasureComplexityList from '../pages/otk/options/MeasureComplexityList';
import MeasureComplexityAdd from '../pages/otk/options/MeasureComplexityAdd';
import SummaryEmailOTKList from '../pages/otk/options/SummaryEmailOTKList';
import SummaryEmailOTKAdd from '../pages/otk/options/SummaryEmailOTKAdd';
import WeatherList from '../pages/painting/options/WeatherList';
import WeatherAdd from '../pages/painting/options/WeatherAdd';
import SummaryEmailPaintingList from '../pages/painting/options/SummaryEmailPaintingList';
import SummaryEmailPaintingAdd from '../pages/painting/options/SummaryEmailPaintingAdd';
import SummaryEmailCalibrationList from '../pages/calibration/options/SummaryEmailCalibrationList';
import SummaryEmailCalibrationAdd from '../pages/calibration/options/SummaryEmailCalibrationAdd';
import TrainingCompanyList from '../pages/training/TrainingCompanyList';
import TrainingCompanyAdd from '../pages/training/TrainingCompanyAdd';
import TrainingTypeList from '../pages/training/options/TrainingTypeList';
import TrainingTypeAdd from '../pages/training/options/TrainingTypeAdd';
import PreviewOTK from '../pages/otk/PreviewOTK';
import PreviewLak from '../pages/painting/PreviewLak';
import PreviewCal from '../pages/calibration/PreviewCal';
import SupportTicketAdd from '../pages/support/SupportTicketAdd';
import SupportTicketList from '../pages/support/SupportTicketList';
import OTKStatistics from '../pages/otk/output/OTKStatistics';

export const components = {
    "InternaChyba": InternaChyba,
    "HomePage": HomePage,
    "ProfilePage": ProfilePage,
    "ExternaChyba" : ExternaChyba,
    "Reklamacia" : Reklamacia,
    "ZoznamInt": ZoznamInt,
    "ZoznamExt": ZoznamExt,
    "ZoznamRek": ZoznamRek,
    "PreviewOTK": PreviewOTK,
    "RoleList": RoleList,
    "DocumentationAdd": DocumentationAdd,
    "Documentation": Documentation,
    "MeasurementProtocol": MeasurementProtocol,
    "SelfControl": SelfControl,
    "NDTProtocol": NDTProtocol,
    "AnnealingProtocol": AnnealingProtocol,
    "SupplierAdd": SupplierAdd,
    "SupplierList": SupplierList,
    "CustomerList": CustomerList,
    "CustomerAdd": CustomerAdd,
    "EmployeeList": EmployeeList,
    "EmployeeAdd": EmployeeAdd,
    "UserList": UserList,
    "UserAdd": UserAdd,
    "RoleList": RoleList,
    "RoleAdd": RoleAdd,
    "PreviewLak": PreviewLak,
    "PaintingIntervalAdd": PaintingIntervalAdd,
    "PaintingIntervalList": PaintingIntervalList,
    "PaintingConditionAdd": PaintingConditionAdd,
    "PaintingConditionList": PaintingConditionList,
    "PaintingTimeConditionAdd": PaintingTimeConditionAdd,
    "PaintingTimeConditionList": PaintingTimeConditionList,
    "PaintingEmployeeConditionAdd": PaintingEmployeeConditionAdd,
    "PaintingEmployeeConditionList": PaintingEmployeeConditionList,
    "WeatherList": WeatherList,
    "WeatherAdd": WeatherAdd,
    "DepartmentList": DepartmentList,
    "DepartmentAdd": DepartmentAdd,
    "PreviewCal": PreviewCal,
    "GaugeList": GaugeList,
    "GaugeAdd": GaugeAdd,
    "GaugeCalibrationList": GaugeCalibrationList,
    "GaugeCalibrationAdd": GaugeCalibrationAdd,
    "CalibrationCompanyList": CalibrationCompanyList,
    "CalibrationCompanyAdd": CalibrationCompanyAdd,
    "CalibrationCompanyCertificateList": CalibrationCompanyCertificateList,
    "CalibrationCompanyCertificateAdd": CalibrationCompanyCertificateAdd,
    "DocumentationDetail": DocumentationDetail,
    "PriorityList": PriorityList,
    "PriorityListMobile": PriorityListMobile,
    "PriorityAdd": PriorityAdd,
    "PriorityDepartmentList": PriorityDepartmentList,
    "PriorityDepartmentAdd": PriorityDepartmentAdd,
    "PriorityLoadAutomaticList": PriorityLoadAutomaticList,
    "PriorityLoadManuallyList": PriorityLoadManuallyList,
    "PriorityLoadAdd": PriorityLoadAdd,
    "ErrorTypeList": ErrorTypeList,
    "ErrorTypeAdd": ErrorTypeAdd,
    "PreventionList": PreventionList,
    "PreventionAdd": PreventionAdd,
    "GroupList": GroupList,
    "GroupAdd": GroupAdd,
    "MeasureComplexityList": MeasureComplexityList,
    "MeasureComplexityAdd": MeasureComplexityAdd,
    "SummaryEmailOTKList": SummaryEmailOTKList,
    "SummaryEmailOTKAdd": SummaryEmailOTKAdd,
    "SummaryEmailPaintingList": SummaryEmailPaintingList,
    "SummaryEmailPaintingAdd": SummaryEmailPaintingAdd,
    "SummaryEmailCalibrationList": SummaryEmailCalibrationList,
    "SummaryEmailCalibrationAdd": SummaryEmailCalibrationAdd,
    "TrainingCompanyList": TrainingCompanyList,
    "TrainingCompanyAdd": TrainingCompanyAdd,
    "TrainingTypeList": TrainingTypeList,
    "TrainingTypeAdd": TrainingTypeAdd,
    "SupportTicketAdd": SupportTicketAdd,
    "SupportTicketList": SupportTicketList,
    "OTKStatistics": OTKStatistics,
    //PRIDAT DALSIE JS SUBORY
  };

function TabPanelList() {

  const [state,dispatch] = useContext(DatabaseContext);

    const changeTab = (event, newValue) => {
        console.log(newValue);
        dispatch({type:'change_tab',name:newValue});
    };

  const handleTabClose = (event, value) => {
    //prevencia pred prepinam medzi tabmi pri mazani
    event.stopPropagation();
    dispatch({type:'close_tab',id:value});

    /*setTabs(tabs => tabs.filter(x => x.value !== value))
    setPanels(panels => panels.filter(p => p.value !== value));
    console.log("BEFORE SELECTED: "+selectedTab);
    if(selectedTab!=='1')setSelectedTab('1');
    console.log("AFTER SELECTED: "+selectedTab);*/
}



return (
<StyledEngineProvider injectFirst>
  <TabContext value={state.selectedTab}>
    {/* maxWidth: { xs: 320, sm: 600, md: 900, lg: 1200, xl: 1536,  } */}
            <div className='w-full bg-[#f6f6f6] flex justify-center border-t-[1px] border-b-[1px] border-[#d1d1d1]'>
        <Box>{/*sx={{ maxWidth: { xs: 320, sm: 600, md: 900, lg: 1200, xl: 1536,  }}} */}
            <TabList onChange={changeTab} aria-label="lab API tabs example" variant="scrollable" scrollButtons={true}>
                {/*<Tab label="Create New" value="1" />
                <Tab   
                icon={
                    <HomeIcon/>
                } iconPosition='start'
                key="1" label="HomePage" value="1" />*/}
                {state.tabs.map(tab => {
                    if(tab.value==1){
                         return <Tab sx={{
                            color: '#333',
                            backgroundColor: '#f9f9f9',
                            py: 1,
                          }}
                            icon={
                                <HomeIcon/>
                            } iconPosition='start'
                            key="1" label="HomePage" value="1" />;
                    }
                    if(tab.value==2){
                        return <Tab sx={{
                            color: 'black',
                            backgroundColor: '#f9f9f9',
                            position: 'absolute',
                            left: '-1000px'
                          }}
                        key={tab.value} label={tab.label} value={tab.value}/>;
                   }
                   return <Tab sx={{
                        color: 'black',
                        backgroundColor: '#f9f9f9',
                    }}
                    icon={
                        <CloseIcon onClick={(e) => handleTabClose(e, tab.value)} />
                    } iconPosition='end'
                    key={tab.value} label={tab.label} value={tab.value}/>;
                })}
            </TabList>
        </Box>
        </div>
            {/*<TabPanel value="1">
                <Button 
                    onClick={createNewTab}
                    variant='contained' color='primary'>
                    Create Dynamic Tab
                </Button>
                </TabPanel>
                <TabPanel key="1" value="1">
                    <HomePage/>
                </TabPanel>*/}
            {state.panels.map((panel) => {
              const Page = components[panel.child || 'HomePage'];
              //if(panel.props)console.log(panel.props);
              return <TabPanel key={panel.value} value={panel.value}>
              <Page {...panel.props}/>
          </TabPanel>;
            } )}
        </TabContext>
        <div className='w-full h-[21px]  text-sm'>
            <hr className='h-1 w-full bg-gray-200'/>
            <div className='flex flex-row'>
                <div className='basis-3/5'></div>
                <span className='basis-1/5 border-x-2 px-2'>Číslo masky: {state?.selectedMask}</span>
                <div className='basis-1/5'></div>
            </div>  
        </div>
        </StyledEngineProvider>
    )
}

export default TabPanelList;